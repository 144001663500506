import { defineStore } from 'pinia';
import $ from 'jquery';
import router from '../router';
import ApiService from '@/services/ApiService';
import { useSettingsStore } from '@/stores/SettingsStore';
import { useGridStore } from '@/stores/GridStore';
const paths = 'PROD'; // LOCAL, DEV, or PROD

var authConfig = {
  authority: 'https://dev-services.nelsonirrigation.com/identity',     // identity server authority
  client_id: 'overlap_client',                                // client
  client_secret: 'clientsecret',                             // client secret
  redirect_uri: 'http://localhost:42042/#/callback',
  scope: 'Overlap'									// the scope that will be requested at login. This is the scope that is required by the api
};

if (paths === 'DEV') {
  authConfig.authority = 'https://dev-services.nelsonirrigation.com/identity';
  authConfig.redirect_uri = 'https://dev-apps.nelsonirrigation.com/overlappro/#/callback';
}

if (paths === 'PROD') {
  authConfig.authority = 'https://auth.nelsonirrigation.com';
  authConfig.redirect_uri = 'https://overlappro.com/#/callback';
}

const ENDPOINT_DISCOVERY = '/.well-known/openid-configuration';
const ENDPOINT_GETTOKENUSER = '/api/identity/user';
// const ENDPOINT_GETSUBSCRIPTIONS = "/api/identity/subscriptions";
const ENDPOINT_SETPASSWORD = '/api/identity/password';
// const ENDPOINT_GETUSERPROPERTIES = "/api/identity/userproperties";
const ENDPOINT_SETUSERPROPERTY = '/api/identity/userproperty';
const LOCALSTORAGE_AUTH_DISCOVERY = 'overlap_auth_discovery';
const LOCALSTORAGE_TOKENS = 'overlap_auth_tokens';
const LOCALSTORAGE_USER = 'overlap_user';
const handleCallbackAutomatically = true;

export const useIdentityStore = defineStore('identity', {
  state () {
    return {
      User: null,
      config: authConfig,
      discovery: null,
      isTos: false
    };
  },
  actions: {
    setLocalStorageObject (key, obj) {
      localStorage.setItem(key, JSON.stringify(obj));
    },
    getLocalStorageObject (key) {
      var str = localStorage.getItem(key);
      if (str === null) { return null; }
      return JSON.parse(str);
    },
    deleteLocalStorageObject (key) {
      localStorage.removeItem(key);
    },
    __getParameterByName (name, url = window.location.href) {
      name = name.replace(/[[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) { return null; }
      if (!results[2]) { return ''; }
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    __ObjectToEncodedParams (obj) {
      var queryString = Object.keys(obj).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
      }).join('&');
      return queryString;
    },
    access_token () {
      let tokens = this.tokens();
      return tokens ? tokens.access_token : null;
    },
    refresh_token () {
      let tokens = this.tokens();
      return tokens ? tokens.refresh_token : null;
    },
    signout () {
      localStorage.removeItem(LOCALSTORAGE_TOKENS);
      localStorage.removeItem(LOCALSTORAGE_USER);
      this.ssoAuthenticate();
    },
    tokens () {
      return this.getLocalStorageObject(LOCALSTORAGE_TOKENS);
    },
    loadDiscoveryDocument () {
      var self = this;
      let p = new Promise(function (success, fail) {
        $.ajax({
          type: 'GET',
          url: self.config.authority + ENDPOINT_DISCOVERY,
          success: function (result) {
            self.discovery = result;
            if (success) {
              self.setLocalStorageObject(LOCALSTORAGE_AUTH_DISCOVERY, result);
              success(result);
            }
          },
          error: fail
        });
      });
      return p;
    },
    ssoAuthenticate () {
      // redirect to the authorize endpoint
      var authParams = {
        redirect_uri: this.config.redirect_uri,
        client_id: this.config.client_id,
        client_secret: this.config.client_secret,
        scope: this.config.scope,
        response_type: 'code',
        // UsePkce : true
      };
      var queryString = this.__ObjectToEncodedParams(authParams);
      window.location.href = this.discovery.authorization_endpoint + '?' + queryString;
    },
    verifyDiscoveryDocument () {
      var self = this;
      let p = new Promise(function (success, fail) {
        if (!self.discovery) {
          var dsc = self.getLocalStorageObject(LOCALSTORAGE_AUTH_DISCOVERY);
          if (dsc) {
            if (!dsc.issuer || dsc.issuer.toLowerCase().indexOf(self.config.authority.toLowerCase()) < 0) {
              // authority changed
              self.deleteLocalStorageObject(LOCALSTORAGE_AUTH_DISCOVERY);
              self.verifyDiscoveryDocument();
            }
            self.discovery = dsc;
            success(self.discovery);
          }
          else {
            self.loadDiscoveryDocument().then(success, fail);
          }
        }
        else {
          if (success) {
            success(self.discovery);
          }
        }
      });
      return p;
    },
    ssoRedeemAuthorizationCode (code) {
      var self = this;
      let p = new Promise(function (success, fail) {
        self.verifyDiscoveryDocument()
          .then(
            function () {
              var params = {
                client_id: self.config.client_id,
                client_secret: self.config.client_secret,
                grant_type: 'authorization_code',
                code: code,
                scope: self.config.scope + ' ' + 'offline_access',
                redirect_uri: self.config.redirect_uri
              };
              // Turn the data object into an array of URL-encoded key/value pairs.
              var queryString = self.__ObjectToEncodedParams(params);
              $.ajax({
                type: 'POST',
                url: self.discovery.token_endpoint,
                data: queryString,
                success: function (result) {
                  self.setLocalStorageObject(LOCALSTORAGE_TOKENS, result);
                  if (success) {
                    success(result);
                  }
                },
                error: function (xhr, status, errorThrown) {
                  try {
                    fail(errorThrown);
                  } catch {
                    alert('error redeeming authorization code');
                  }
                }
              });
            },
            function () {
              alert('unable to load SSO discovery document');
              fail();
            }
          );
      });
      return p;
    },
    ssoRefreshToken (refresh) {
      var self = this;
      let p = new Promise(function (success, fail) {
        self.verifyDiscoveryDocument()
          .then(
            function () {
              var params = {
                client_id: self.config.client_id,
                client_secret: self.config.client_secret,
                grant_type: 'refresh_token',
                refresh_token: refresh
              };
              // Turn the data object into an array of URL-encoded key/value pairs.
              var queryString = self.__ObjectToEncodedParams(params);
              $.ajax({
                type: 'POST',
                url: self.discovery.token_endpoint,
                data: queryString,
                success: function (result) {
                  self.setLocalStorageObject(LOCALSTORAGE_TOKENS, result);
                  if (success) {
                    success(result);
                  }
                },
                error: ()=> { self.ssoAuthenticate(); }
              });
            },
            function () {
              console.log('refresh token expired...routing to login page');
              self.ssoAuthenticate();
              fail();
            }
          );
      });
      return p;
    },
    parseAuthorizationCodeResponse () {
      return this.__getParameterByName('code');
    },
    init () {
      var self = this;
      this.verifyDiscoveryDocument().then(() => {
      });
      if (handleCallbackAutomatically) {
        self.handleCallbackResponse();
      }
    },
    handleCallbackResponse () {
      var self = this;
      if (window.location.href.toLowerCase().indexOf(self.config.redirect_uri.toLowerCase()) >= 0) {
        var authCode = self.parseAuthorizationCodeResponse();
        if (authCode) {
          // now request an access token
          self.ssoRedeemAuthorizationCode(authCode).then(
            function () {
              // got a token
              // use access_token for API calls.
              // when this token expires, use refresh_token to request a new access token
              ApiService.setAuthHeader(self.access_token());
              router.push({ name: 'overlap' });
            },
            function (errResponse) {
              alert('failed to redeem authorization code: ' + errResponse);
              // window.location.href = "/"; // TODO! route to access denied view
            }
          );
        }
        else {
          alert('no token was received');
        }
      }
    },
    // verify a token with introspection endpoint. 
    inspectToken (access_token) {
      var self = this;
      let p = new Promise(function (success, fail) {
        self.verifyDiscoveryDocument()
          .then(
            function () {
              var params = {
                token: access_token
              };
              // Turn the data object into an array of URL-encoded key/value pairs.
              var queryString = self.__ObjectToEncodedParams(params);
              $.ajax({
                type: 'POST',
                headers: { 'Authorization': 'Basic ' + btoa(self.config.scope + ':' + self.config.client_secret) },
                url: self.discovery.introspection_endpoint,
                data: queryString,
                success: success,
                error: fail
              });
            },
            function () {
              fail();
            }
          );
      });
      return p;
    },
    isTokenValid (access_token) {
      var self = this;
      return new Promise(function (success, fail) {
        if (!access_token) {
          access_token = self.access_token();
        }
        return self.inspectToken(access_token)
          .then((t) => {
            success(t.active);
          }, () => {
            fail(false);
          });
      });
    },
    getTokenUser () {
      var self = this;
      let p = new Promise(function (success, fail) {
        $.ajax({
          type: 'GET',
          headers: { 'Authorization': 'Bearer ' + self.access_token() },
          url: self.config.authority + ENDPOINT_GETTOKENUSER,
          success: function (result) {
            self.User = result;
            self.setLocalStorageObject(LOCALSTORAGE_USER, result);
            if (success) {
              success(result);
            }
          },
          error: fail
        });
      });
      return p;
    },
    getUserSubscriptions () {
      // TODO!
      // GET this.config.authority + ENDPOINT_GETSUBSCRIPTIONS,
    },
    setUserPassword (newPassword) {
      var self = this;
      $.ajax({
        type: 'POST',
        headers: { 'Authorization': 'Bearer ' + self.access_token() },
        url: self.config.authority + ENDPOINT_SETPASSWORD,
        data: JSON.stringify({
          sendEmail: true,
          bccEmail: '',
          password: newPassword,
        }),
        contentType: 'application/json',
        success: function (data) {
          if (process.env.NODE_ENV !== 'production') {
            console.log(data);
          }
          self.signout();
        },
        error: function (error) {
          console.log(`Error ${error}`);
        }
      });
    },
    getUserProperties () {
      var self = this;
      let p = new Promise(function (success, fail) {
        $.ajax({
          type: 'GET',
          headers: { 'Authorization': 'Bearer ' + self.access_token() },
          url: self.config.authority + ENDPOINT_GETTOKENUSER,
          success: function (result) {
            self.User = result;
            useSettingsStore().restoreUnits();
            useGridStore().restoreSettings();
            self.setLocalStorageObject(LOCALSTORAGE_USER, result);
            if (success) {
              success(result);
            }
          },
          error: fail
        });
      });
      return p;
    },
    setUserProperty (property) {
      var self = this;
      $.ajax({
        type: 'POST',
        headers: { 'Authorization': 'Bearer ' + self.access_token() },
        url: self.config.authority + ENDPOINT_SETUSERPROPERTY,
        data: JSON.stringify(property),
        contentType: 'application/json',
        success: function () {
          self.getUserProperties();
        },
        error: function (error) {
          console.log(`Error ${error}`);
        }
      });
    },
  }
});
