import { useIdentityStore } from '@/stores/IdentityStore';
import { useModalStore } from '@/stores/ModalStore';
import { createRouter, createWebHashHistory } from 'vue-router';
import ApiService from '@/services/ApiService';
import OverlapproView from '../views/OverlapproView.vue';

const routes = [
  {
    path: '/',
    name: 'overlap',
    component: OverlapproView
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach(async (to, from) => {
  // dynamic page title
  document.title = to.meta?.title ?? 'Nelson Overlap';
  if (process.env.NODE_ENV !== 'production') {
    console.log('To Path: ' + to.path);
    console.log('From Path: ' + from.path);
  }
  const identityStore = useIdentityStore();
  if (to.path !== '/callback') {
    // check to see if discovery document is stored
    if (!identityStore.discovery) {
      await identityStore.verifyDiscoveryDocument();
    }
    var token = identityStore.access_token();
    if (!token) {
      identityStore.ssoAuthenticate();
    }
    else {
      var bTokenActive = await identityStore.isTokenValid();
      if (!bTokenActive) {
        var refreshToken = identityStore.refresh_token();
        if (refreshToken) {
          await identityStore.ssoRefreshToken(refreshToken);
          token = identityStore.access_token();
          if (!token) {
            identityStore.ssoAuthenticate();
          }
        }
      }
      if (!ApiService.token) { ApiService.setAuthHeader(identityStore.access_token()); }
      var user = JSON.parse(localStorage.getItem('overlap_user'));
      const modalStore = useModalStore();
      if (!user) {
        identityStore.getTokenUser().then(() => {
          if (identityStore.User.properties.Overlap_HasAgreed === null) {
            modalStore.showModal('tos');
          }
        });
      }
      else {
        identityStore.User = user;
        if (identityStore.User.properties.Overlap_HasAgreed === null) {
          modalStore.showModal('tos');
        }
      }
    }
  }
});
router.onError(err => {
  print('<!-- router error: ' + err.message + ' -->');
});

export default router;
