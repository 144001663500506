<template>
  <div ref="dataContainer" class="data-container">
    <div class="title">Data</div>
    <div class="data-line"></div>
    <div class="data-input-holder">
      <div class="data-input-expand-options">
        <div id="di-expand-button" class="expand-option" @click="expandAll">Expand All</div>
        <div id="di-contract-button" class="expand-option" @click="contractAll">Contract All</div>
      </div>
    </div>
    <div ref="dataOptions">
      <DataInputOption type="sprinkler">
        <SprinklerInput/>
      </DataInputOption>
      <DataInputOption type="layout">
        <LayoutInput/>
      </DataInputOption>
      <DataInputOption type="spacing">
        <SpacingInput />
      </DataInputOption>
      <DataInputOption type="treeVineSpacing">
        <TreeVineSpacingInput />
      </DataInputOption>
      <DataInputOption type="filters">
        <FiltersInput/>
      </DataInputOption>
      <DataInputOption type="wateredRegion">
        <WateredRegionInput />
      </DataInputOption>
    </div>
    <div class="data-button-container">
      <div id="di-analyzeButton" class="data-input-btn submit-btn" ref="submitButton" @click="handleAnalyze">{{submitButtonText}}</div>
    </div>
  </div>
</template>

<script>
import { useIdentityStore } from '@/stores/IdentityStore';
import { useModalStore } from '@/stores/ModalStore';
import { useSprinklerStore } from '@/stores/SprinklerStore';
import { getCurrentInstance } from 'vue';
import DataInputOption from '@/components/data_input_components/DataInputOption';
import FiltersInput from '@/components/data_input_components/FiltersInput';
import LayoutInput from '@/components/data_input_components/LayoutInput';
import SpacingInput from '@/components/data_input_components/SpacingInput';
import SprinklerInput from '@/components/data_input_components/SprinklerInput';
import TreeVineSpacingInput from '@/components/data_input_components/TreeVineSpacingInput';
import WateredRegionInput from '@/components/data_input_components/WateredRegionInput';

export default {
  components: {
    DataInputOption,
    FiltersInput,
    LayoutInput,
    SpacingInput,
    SprinklerInput,
    TreeVineSpacingInput,
    WateredRegionInput
  },
  mounted () {
    useIdentityStore().getUserProperties();
    useSprinklerStore().setDataInputPanel(this);
  },
  computed: {
    submitButtonText () {
      return useSprinklerStore().selectedRecord == null ? 'ANALYZE' : 'UPDATE';
    },
  },
  setup (){
    useSprinklerStore().currentInstance = getCurrentInstance();
  },
  methods: {
    handleAnalyze () {
      const sprinklerStore = useSprinklerStore();
      if (sprinklerStore.hasIncompleteInputs) {
        useModalStore().showModal('inputWarn');
      } else {
        sprinklerStore.analyze(this.internalInstance);
      }
    },
    expandAll () {
      useSprinklerStore().expandAllDataInputs();
    },
    contractAll () {
      useSprinklerStore().contractAllDataInputs();
    },
    handleUpdate () {
    },
    handleOpenDataInput () {
      const sprinklerStore = useSprinklerStore();
      if (!sprinklerStore.getDataInputExpanded) {
        this.$refs.dataContainer.classList.add('data-container-expanded');
        sprinklerStore.shrinkSprinklerGridPanel();
      } else {
        this.$refs.dataContainer.classList.remove('data-container-expanded');
        sprinklerStore.expandSprinklerGridPanel();
      }
    }
  }
};
</script>

<style>
/* these style apply across all divs */
.title {
  font-size: 110%;
  font-weight: 700;
  white-space: nowrap;
  padding-top:10px;
  padding-bottom:10px;
  padding-left: 10px;
}
.data-line {
  width: 100%;
  height: 1px;
  background-color: grey;
}
.input-unit {
  font-weight: 600;
  font-size: 70%;
  flex: none !important;
}
label {
  font-size: 80%;
}
@media (max-width: 768px) {
  label {
    font-size: 75%;
  }
}
</style>

<style scoped>
.data-container {
  position: relative;
  width: 40vw;
  min-width: 32vw;
  max-width: 500px;
  width: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid grey;
}
@media (max-width: 768px) {
  .data-container {
    width: 60vw;
  }
}
.data-container-expanded {
  width: 100vw;
}
.input-expand-btn {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.input-expand-btn-text {
  position: fixed;
  text-align: right;
  padding-right: 10px;
}
.data-input-holder {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 2px;
}
.data-input-expand-options {
  display: flex;
  column-gap: 10px;
  margin-left: 0px;
}
.expand-option {
  font-size: 70%;
}
.data-button-container {
  position: sticky;
  margin-top: auto;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  column-gap: 5px;
  width: 100%;
  padding-top:10px;
  background-color: white;
  padding-bottom: 5px;
  padding-right: 5px;
}
.data-input-btn {
  padding-top: 8px;
  padding-bottom: 8px;
  width: 30%;
  user-select: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .85em;
  font-weight: 600;
  cursor: pointer;
}
.submit-btn {
  background-color: #00bd5e;
  margin-right: 5px;
}
.submit-btn:hover {
  background-color: #00b254;
}
</style>